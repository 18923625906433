const Home = () => import("../pages/indexPage.vue");
const Contact = () => import("../pages/contactUs.vue");
const Security = () => import("../pages/ourSecurity.vue");
const OurStory = () => import("../pages/ourStory.vue");
const FrequentlyAskedQuestions = () =>
  import("../pages/frequentlyAskedQuestions.vue");
const PrivacyPolicy = () => import("../pages/privacyPolicy.vue");
const TermsOfService = () => import("../pages/termsOfServices.vue");
const KYCPolicy = () => import("../pages/kycPolicy.vue");

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      layout: "AppHomeLayout",
    },
  },
  {
    path: "/frequently-asked-questions",
    name: "faq",
    component: FrequentlyAskedQuestions,
    meta: {
      layout: "AppHomeLayout",
    },
  },
  {
    path: "/our-story",
    name: "our-story",
    component: OurStory,
    meta: {
      layout: "AppHomeLayout",
    },
  },
  {
    path: "/contact-us",
    name: "contact",
    component: Contact,
    meta: {
      layout: "AppHomeLayout",
    },
  },
  {
    path: "/security",
    name: "security",
    component: Security,
    meta: {
      layout: "AppHomeLayout",
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
    meta: {
      layout: "AppHomeLayout",
    },
  },
  {
    path: "/terms-of-services",
    name: "terms-of-service",
    component: TermsOfService,
    meta: {
      layout: "AppHomeLayout",
    },
  },
  {
    path: "/kyc-policy",
    name: "kyc-policy",
    component: KYCPolicy,
    meta: {
      layout: "AppHomeLayout",
    },
  },
];

export default routes;
